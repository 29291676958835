import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/Users/abdullatieffathoni/Code/javascript/tds-web/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const PageDescription = makeShortcode("PageDescription");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <PageDescription mdxType="PageDescription">
      <p><inlineCode parentName="p">{`Avatar`}</inlineCode>{` adalah sebuah unsur UI yang digunakan untuk merepresentasikan user atau alter ego mereka, juga dikenal sebagai profile picture.`}</p>
    </PageDescription>
    <h2>{`Example`}</h2>
    <h3>{`Avatar Illustrasi`}</h3>
    <p>{`Pilihan avatar ilustrasi untuk kondisi pengguna yang tidak memiliki foto.`}</p>
    <span {...{
      "className": "gatsby-resp-image-wrapper",
      "style": {
        "position": "relative",
        "display": "block",
        "marginLeft": "auto",
        "marginRight": "auto",
        "maxWidth": "850px"
      }
    }}>{`
      `}<span parentName="span" {...{
        "className": "gatsby-resp-image-background-image",
        "style": {
          "paddingBottom": "41.66666666666667%",
          "position": "relative",
          "bottom": "0",
          "left": "0",
          "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAICAYAAAD5nd/tAAAACXBIWXMAAAsSAAALEgHS3X78AAABmUlEQVQoz2PgZWJgZIACWeskJhhbPXwanF31/z8D0UBEXIJNPXG1i07JWXEQXyFosq1K4mY1EFvFt8lCJ3WtFYitFzeLQSd7N2EDtVI3WWrHr/mvHjZ7mevX/2y6cUv/68QuegSSs8rbctGxbN9/yZDFMmC1cYsZhUXEGQR4ORmERcTA+kG0kLAokBaFiKlGLhaVtataJedQGwlSoB02fRoQ14LYOqFTyvRCJk2XybnEAeJbz//PYNb/HxxE9qv+M6qGzWHwu/2fwfP0f4aTwGDpgAYNI5KDkdlMWDzEiK4ICTCDpTTiFgtoJq7p1ErZ6AgS1Yxf3q+TvM5YLWqBrEb0wgneG/8zqUfMzVKPWuinGjZbUjV0ZqNm13tG1ZDpVaoR8wXUQmfEqoXNVFENnmqrGjRFmUEzeb2tdvSK/6qBU9bH/v/PpOTd/U3Zty9BNXyeipJn5wnTJf9Zlb2765S8e1LUIhYIAdklOkDvAtUlKPtN5FP07LJW9OyWVPTqkVDw7OJnkLapYJWyKIqRsizWhjqdlYECAAAme3xMS/H+qQAAAABJRU5ErkJggg==')",
          "backgroundSize": "cover",
          "display": "block"
        }
      }}></span>{`
  `}<img parentName="span" {...{
        "className": "gatsby-resp-image-image",
        "alt": "avatar",
        "title": "avatar",
        "src": "/static/e8899dea969083db9a6cdfe956d99e9e/e5284/illustration.png",
        "srcSet": ["/static/e8899dea969083db9a6cdfe956d99e9e/7fc1e/illustration.png 288w", "/static/e8899dea969083db9a6cdfe956d99e9e/a5df1/illustration.png 576w", "/static/e8899dea969083db9a6cdfe956d99e9e/e5284/illustration.png 850w"],
        "sizes": "(max-width: 850px) 100vw, 850px",
        "style": {
          "width": "100%",
          "height": "100%",
          "margin": "0",
          "verticalAlign": "middle",
          "position": "absolute",
          "top": "0",
          "left": "0"
        },
        "loading": "lazy"
      }}></img>{`
    `}</span>
    <h3>{`Avatar Foto`}</h3>
    <p>{`Pilihan avatar dengan foto profil.`}</p>
    <span {...{
      "className": "gatsby-resp-image-wrapper",
      "style": {
        "position": "relative",
        "display": "block",
        "marginLeft": "auto",
        "marginRight": "auto",
        "maxWidth": "850px"
      }
    }}>{`
      `}<span parentName="span" {...{
        "className": "gatsby-resp-image-background-image",
        "style": {
          "paddingBottom": "24.305555555555557%",
          "position": "relative",
          "bottom": "0",
          "left": "0",
          "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAFCAYAAABFA8wzAAAACXBIWXMAAAsSAAALEgHS3X78AAABEElEQVQY02NggAIWNk4mBjgQYGRAA//v/GcgBB5vnQUyid+LgYFLFiSgryXtYWmprtgYLsZSVRgf3LuwjSPEXEE138XIFSRfEOUfXBzqwtPXWGWyalqbIVBI1NHO0nn5lDaeqvxMZ7CpHm6B/zUMHfaD2CVxju8iAu3mTopm4KoOsvkSZqtstKClIGxpRdIxkHx7fMDS5ig3pc6SzOglrYVxQCEpS1OjlBOLO7lKU6MdwAY2d85YV9U6PQfELovztAt2MFYFsY8qKsgdAbr/////zD+PP+JmIALMqUpHCqP/iDDKibPDUPzk1AmGOe/+M+h2HmY4sqib4ePFVQwS3DwMwe62jBOqMhmKksLBcQAAZr1UfYF1ID0AAAAASUVORK5CYII=')",
          "backgroundSize": "cover",
          "display": "block"
        }
      }}></span>{`
  `}<img parentName="span" {...{
        "className": "gatsby-resp-image-image",
        "alt": "avatar",
        "title": "avatar",
        "src": "/static/86da07a6bca126e58c97f93dbcb4c2bb/e5284/photo.png",
        "srcSet": ["/static/86da07a6bca126e58c97f93dbcb4c2bb/7fc1e/photo.png 288w", "/static/86da07a6bca126e58c97f93dbcb4c2bb/a5df1/photo.png 576w", "/static/86da07a6bca126e58c97f93dbcb4c2bb/e5284/photo.png 850w"],
        "sizes": "(max-width: 850px) 100vw, 850px",
        "style": {
          "width": "100%",
          "height": "100%",
          "margin": "0",
          "verticalAlign": "middle",
          "position": "absolute",
          "top": "0",
          "left": "0"
        },
        "loading": "lazy"
      }}></img>{`
    `}</span>
    <h3>{`Avatar Inisal`}</h3>
    <p>{`Pilihan avatar dengan inisial nama maksimal 2 karakter.`}</p>
    <span {...{
      "className": "gatsby-resp-image-wrapper",
      "style": {
        "position": "relative",
        "display": "block",
        "marginLeft": "auto",
        "marginRight": "auto",
        "maxWidth": "850px"
      }
    }}>{`
      `}<span parentName="span" {...{
        "className": "gatsby-resp-image-background-image",
        "style": {
          "paddingBottom": "24.305555555555557%",
          "position": "relative",
          "bottom": "0",
          "left": "0",
          "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAFCAYAAABFA8wzAAAACXBIWXMAAAsSAAALEgHS3X78AAABC0lEQVQY02NggAJ2ZUcmGJuZgYGRAQ38//+fgRCwqz8KJJWCvBjk42VBAoLm8R6CTuWK6nKiLCoeecGKsXM41FxSVLX8y1xB8voBxcEqftU8ev6FJnqBZYZantmihsFlzgou2Txq7tnOYFOjGlf/98qfuh/E9sib9s4tZ/JcQbdaLqfMSV+sknqMzBJ6wiwSeo6B5M3ie5Yq+dcrWSb1RZvFd8c5Z02Vcs2emmKZ3M/lnD3VAWygdXLvOtuUvhwQWyes2U4nvEUVxJbxrJQziO5gYRDzY2bQTuZmIAJYp0zAHkaqgQ2YqvXSwGqqpm5k2HXiOsPRC3cZgkpnM3Qu3MWoG9HK4Jo9GRwHAMQ7T35GY6b0AAAAAElFTkSuQmCC')",
          "backgroundSize": "cover",
          "display": "block"
        }
      }}></span>{`
  `}<img parentName="span" {...{
        "className": "gatsby-resp-image-image",
        "alt": "avatar",
        "title": "avatar",
        "src": "/static/9256265096ff66729da51406ac639add/e5284/initial.png",
        "srcSet": ["/static/9256265096ff66729da51406ac639add/7fc1e/initial.png 288w", "/static/9256265096ff66729da51406ac639add/a5df1/initial.png 576w", "/static/9256265096ff66729da51406ac639add/e5284/initial.png 850w"],
        "sizes": "(max-width: 850px) 100vw, 850px",
        "style": {
          "width": "100%",
          "height": "100%",
          "margin": "0",
          "verticalAlign": "middle",
          "position": "absolute",
          "top": "0",
          "left": "0"
        },
        "loading": "lazy"
      }}></img>{`
    `}</span>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      